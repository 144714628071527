/*
(function ($, root, undefined) {

	$(function () {

		'use strict';

		// DOM ready, take it away

	});

})(jQuery, this);

*/
//COURS SLIDER*/
// $(document).ready(function(){

// 	if($(document).width()>=1080){

//   $('.sliderDanse').slick({

//   		infinite: true,
//   		autoplay: true,
//  	 	slidesToShow: 3,
//   		slidesToScroll: 3,
//   		adaptiveHeight: true

// 	});

// 	}
// 	else{
// 		$('.sliderDanse').slick({

//   		infinite: true,
//   		autoplay: true,
//  	 	slidesToShow: 1,
//   		slidesToScroll: 1,
//   		adaptiveHeight: true

// 	});
// 	}

// });
(function($){

		//functions
	var onReady,
		initListeners,
		initShowcaser,
		initGoogleMaps,
		onClickEvents,
		scrollTo,
		onScroll,
		onResize,
		toggleMobileMenu,
		toggleDownMobileMenu,
		loadGoogleMaps,
		initialize,


		// arrays & objects
		profInfo = {},
		coursInfo = {},

		// booleans
		triggeredScrolling = false,
		mobileMenu = false,
		isLocalhost = window.location.host == "www.troupecreationdanse.dev",
		animating = false,

		// int
		initialNavHeight = "75px",
		expandNavHeight,
		windowWidth = 0,

		// string
		baseUrl = isLocalhost ? "" : window.location.host == "www.revolvemedia.ca" ? "/tcd" : "",

		newsSwiper,
		coursesSwiper,

		// selectors
		$coursSection,
		$profSection,
		$eventsListItems,
		$menuBtnInner,
		$body,
		$window,
		$htmlBody,
		// $profsListItems,
		// $showcaser,
		$menuMobile,
		$header,
		$menuItems,
		$mapCanvas,
		$newsSlideshow;





	onReady = function(){

		console.log('onReady')

		$coursSection = $(".courses");
		$profSection = $(".teachers");
		$eventsListItems = $(".events-list li");
		// $profsListItems = $("#listes-profs li");
		$menuBtnInner = $(".header__menu.inner", ".header");
		// $showcaser = $("#the-showcaser");
		$body = $("body");
		$window = $(window);
		$htmlBody = $("html,body");
		$menuMobile = $(".mobile-menu");
		$header = $(".header");
		$menuItems = $(".menu", ".header .nav");
		$mapCanvas = $("#map_canvas");
		$newsSlideshow = $("#slider-news");
		windowWidth = $window.width();

		// // Expand description
		// $(".more", ".cours").click(function(){
		// 	$(this).parent().find(".desc").slideToggle(200);
		// });

		// Defining section height positions
		if( $profSection.length>0 ){
			profInfo.start = $profSection.offset().top - 101;
			profInfo.end = parseInt( profInfo.start + $profSection.innerHeight() - 1 );
			profInfo.btn = $("#menu-profs");

			coursInfo.start = $coursSection.offset().top - 101;
			coursInfo.end = parseInt( coursInfo.start + $coursSection.innerHeight() );
			coursInfo.btn = $("#menu-cours");
		}


		initListeners();

		// if( $newsSlideshow.length )
		// 	initNewsSlideshow();

		// if( $showcaser.length )
		// 	initShowcaser();

		// if (){
		// 	newsSwiper = new Swiper('.swiper-container', {
		// 	    pagination: '.swiper-pagination',
		// 	    paginationClickable: true,
		// 	    spaceBetween: 30,
		// 	});
		// }

	}

	initListeners = function(){
		$eventsListItems.click( onClickEvents );
		$window.scroll( onScroll );
		// $window.resize( onResize );
		// $profsListItems.click( swapTeacher );

		// Init lightcase
		$('a[data-rel^=lightcase]').lightcase({
			video: {
				autoplay: true
			}
		});

		if ($mapCanvas.length) {
			initGoogleMaps();
		}

		$menuMobile.click( toggleMobileMenu )

		// Menu scrolling
		if ($profSection.length) {
			$menuBtnInner.click(scrollTo);
		}

		coursesSwiper = new Swiper('.courses-container', {
			slideClass: 'courses-item',
			slideActiveClass: 'courses-item--active',
			wrapperClass: 'courses',
		    pagination: '.courses-pagination',
		    // slidesPerView: 3,
		    slidesPerView:'auto',
		    paginationClickable: true,
		    spaceBetween: 0,
		    freeMode: true
		});

		newsSwiper = new Swiper('.news-container', {
			slideClass: 'news-item',
			slideActiveClass: 'news-item--active',
			wrapperClass: 'news',
		    pagination: '.news-pagination',
		    slidesPerView: 1,
		    paginationClickable: true,
		    spaceBetween: 30
		});
	}

	// initShowcaser = function(){
	// 	$showcaser.showcaser({verticalNavigation: true, animationDelay: 5000});
	// }

	// initNewsSlideshow = function(){
	// 	$("#slider-news").responsiveSlides({
	// 	  auto: false,
	// 	  pager: true,
	// 	  nav: true,
	// 	  speed: 500,
	// 	  maxwidth: 800,
	// 	  namespace: "centered-btns"
	// 	});
	// }

	scrollTo = function(e){
		e.preventDefault();

		var $this = $(this);
		var bodyPaddingTop = parseInt($body.css("padding-top"));
		var target = $this.attr("href").replace("/#", ".");

		if (typeof history.pushState !== "undefined"){
			history.pushState(null, null, $this.attr("href"));
		} else{
			window.location.href = $this.attr("href");
		}

		if ($(target).length) {
			var scrollToPosition = parseInt($(target).offset().top - bodyPaddingTop);
			var currentScroll = $(document).scrollTop();
			var scrollDifference = Math.abs(currentScroll - scrollToPosition);
			var animationDuration = scrollDifference/4;

			if (scrollToPosition != currentScroll) {
				triggeredScrolling = true;
				$htmlBody.animate(
					{
						scrollTop: scrollToPosition
					},
					{
						duration: animationDuration,
						easing: 'swing',
						complete: function(){
							triggeredScrolling = false;
						}
					}
				);
			}

			$(".header__menu.inner.selected", ".header").removeClass("selected");
			$this.addClass("selected");

			if ($header.hasClass('header--expand')) {
				toggleDownMobileMenu();
			}
		}
	}

	// onResize = function () {
	// 	windowWidth = $(window).width()
	// 	if (windowWidth < 800) {
	// 		coursesSwiper.
	// 	}
	// }

	onScroll = function(){
		var scrollTop = $(document).scrollTop();

		if (!triggeredScrolling) {

			var $profBtn = profInfo.btn;
			var $coursBtn = coursInfo.btn;

			// Professeures
			if (scrollTop >= profInfo.start && scrollTop <= profInfo.end && !$profBtn.hasClass("selected")) {
				$(".header__menu.inner.selected", ".header").removeClass("selected");
				$profBtn.addClass("selected");

				document.title = "Professeures | Troupe Création Danse";

				if (history.replaceState) {
					history.replaceState(null, "Professeures | Troupe Création Danse", baseUrl+"/#professeures");
				}
			// Cours et tarifs
			} else if (scrollTop >= coursInfo.start && scrollTop <= coursInfo.end && !$coursBtn.hasClass("selected")) {
				$(".header__menu.inner.selected", ".header").removeClass("selected");
				$coursBtn.addClass("selected");

				document.title = "Cours et tarifs | Troupe Création Danse";

				if (history.replaceState) {
					history.replaceState(null, "Cours et tarifs | Troupe Création Danse", baseUrl+"/#cours-et-tarifs");
				}

			// Default state
			} else if ((scrollTop <= coursInfo.start || scrollTop >= profInfo.end) && $(".header__menu.inner.selected", ".header").length > 0) {
				$(".header__menu.inner.selected", ".header").removeClass("selected");

				document.title = "Troupe Création Danse";

				if (history.replaceState) {
					history.replaceState(null, "Troupe Création Danse", baseUrl+"/");
				}
			}
		}
	}

	onClickEvents = function(){
		var multipleMonths = false;
		var months;
		var month = $(this).attr("data-month");
		var highlightColor = "#b6b6b6";

		if( month.indexOf("-") >= 0 ){
			multipleMonths = true;
			months = month.split("-");
		}

		var id = multipleMonths?"#"+months[0] : "#"+month;
		var scrollToPosition = parseInt($(id).offset().top - 140);
		var currentScroll = $(document).scrollTop();
		var scrollDifference = Math.abs(currentScroll - scrollToPosition);
		var animationDuration = scrollDifference/4;

		$htmlBody.animate({ scrollTop: scrollToPosition }, { duration: 200, easing: 'swing'});
		if( multipleMonths ){
			var month1 = $("#"+months[0]+".col3");
			var month2 = $("#"+months[1]+".col3");
			setTimeout(function(){month1.addClass("highlighted");}, 0);
			month2.addClass("highlighted");
			window.setTimeout(function(){month1.removeClass("highlighted"); month2.removeClass("highlighted");}, 2000);
		}else{
			var month1 = $("#"+month+".col3");
			month1.addClass("highlighted");
			window.setTimeout(function(){month1.removeClass("highlighted");}, 2000);
		}

	}

	// swapTeacher = function(){

	// 	$this = $(this);
	// 	var delay = 400;
	// 	var index = $this.index();
	// 	var oldDesc = $(".selected", ".profs-showcase");
	// 	var oldTeacher = $(".selected", "#listes-profs");
	// 	var descPadding;
	// 	var newDesc = $(".profs-showcase article").eq(index);
	// 	var newDescOffset;
	// 	var newTeacher = $this;

	// 	if( index != oldTeacher.index() ){
	// 		newDescOffset = "-"+oldDesc.height()+"px";
	// 		descPadding = oldDesc.parent().css("padding-left");
	// 		oldDesc.removeClass("selected").css("display", "none");
	// 		oldTeacher.removeClass("selected");
	// 		newTeacher.addClass("selected");
	// 		newDesc.fadeIn(delay).addClass("selected");
	// 	}
	// }

	//Mobile menu
	toggleMobileMenu = function(e){
		e.preventDefault();
		var $this = $(this);
		// var menuItems = $(".menu", ".header .nav");
		var $nav = $(".nav", ".header");

		if( !$header.hasClass('header--expand') ){
			$header.addClass('header--expand')
			// mobileMenu = true;
			// $menuItems.css({"visibility":"hidden", "display":"block"}).removeClass("hide");
			// expandNavHeight = parseInt($nav.height()+25)+"px";
			// $this.addClass("expanded");
			// $header.animate({height:expandNavHeight}, 150);
			// $menuItems.css({"visibility":"visible"});

		}else{
			toggleDownMobileMenu();
			// mobileMenu = false;
		}
	}

	toggleDownMobileMenu = function(){
		$header.removeClass('header--expand');
		// $header.animate({height:initialNavHeight}, {duration: 150, complete: function(){ $menuItems.css({"visibility":"", "display":""}); $this.removeClass("expanded"); }});
	}

	// Google Maps API call
	initGoogleMaps = function() {
		var script = document.createElement("script");
		var key = ( isLocalhost ) ? 'AIzaSyBNlM9EMzt-zHE-8InVuo97aZ1fWDad7s4' : 'AIzaSyCyO9HeVeExETdnGkPEn4WNFmVmmytc-Dk';
		script.type = "text/javascript";

		script.src="http://maps.googleapis.com/maps/api/js?key="+key+"&sensor=false&callback=initialize";
		document.body.appendChild(script);
	}

	$(document).ready( onReady );

})(jQuery);

function initialize(){
	var latLng = new google.maps.LatLng(45.718765,-73.664803);
	var mapOptions = {
		center: latLng,
		mapTypeId: google.maps.MapTypeId.ROADMAP,
		panControl: true,
		streetViewControl: true,
		zoom: 15,
		zoomControl: true
	}

	map = new google.maps.Map(document.getElementById("map_canvas"), mapOptions);

	var marker = new google.maps.Marker({
		position: latLng,
		map: map,
		title: 'Troupe Création Danse - École Le Castelet'
	});
}
